import { graphql } from 'gatsby';
import { IAboutPage } from '../components/shared/contentfu.interface';
import { PageContext } from '../pageContext';
import { ProfileCard } from '../components/shared/card';
import React from 'react';
import Seo from '../components/Seo';
import SiteLayout from '../components/layout/layout';
import { theme } from '../components/shared/theme';

import { Col, Row } from 'antd';
import { H1, TextSmall } from '../components/shared/typography';

interface Props {
  data: {
    allContentfulAboutPage: {
      nodes: IAboutPage[];
    };
  };
  pageContext: PageContext;
}

const AboutPage: React.FC<Props> = ({ data, pageContext }: Props) => {
  const content = data.allContentfulAboutPage.nodes[0];

  return (
    <SiteLayout
      locale={pageContext.locale}
      hasHeader={false}
      componentName={pageContext.componentName}
    >
      <Seo
        title="About"
        description="Information about privatcoronatest as a company and their history"
        keywords={[
          `privatcoronatest`,
          `about`,
          `history`,
          `covid-19`,
          `corona`,
          `company`,
        ]}
        pathname="about"
        />
      <Row justify='center' style={{background: 'white', width: '100%'}}>
        <H1 style={{color: theme.colors.brandLight.primary, paddingTop: '4rem'}}>{content.title}</H1>
        <Col  xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 18 }} lg={{ span: 18 }}>
          <TextSmall style={{ textAlign: 'center' }}>{content.text.text}</TextSmall>
        </Col>
      </Row>
      <Row gutter={[40,40]} style={{ paddingTop: '2rem', paddingBottom: '2rem', marginBottom: '3rem', background: 'white', margin: '0' }} justify="center">
          {content.team &&
            content.team.map((member, key) => (
              <Col
                key={key}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
              >
              <ProfileCard profileInfo={member} key={key} />
        </Col>
            ))}
      </Row>
    </SiteLayout>
  );
};
export default AboutPage;

export const query = graphql`
  query AboutQuery($locale: String) {
    allContentfulAboutPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        text {
          text
        }
        team {
          name
          title
          img {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
